<template>
  <div style="display: flex; background-color: #b0d9ff" @keydown.enter="registerUser">
    <v-row class="main-row">
      <v-col class="register-col">
        <div class="language-row">
          <LanguageDropDown />
        </div>
        <v-col class="form-col">
          <div class="header-row">
            <div class="register-decoration-row" />
            <v-row class="title-row">
              <h1 class="register-title">
                {{ $t("Signup") }}
              </h1>
            </v-row>
              <BaseInput
                  id="firstName"
                  v-model="newUser.firstName"
                  :error="errors && !newUser.firstName"
                  :required="true"
                  :placeholder="$t('FirstName')"
              />
                <BaseInput
                    id="lastName"
                    v-model="newUser.lastName"
                    :error="errors && !newUser.lastName"
                    :required="true"
                    :placeholder="$t('LastName')"
                    style="margin-top: 5px;"
                />
                <BaseInput
                    id="email"
                    v-model="newUser.email"
                    :error="errors && !newUser.email"
                    :required="true"
                    :placeholder="$t('UserEmail')"
                    style="margin-top: 5px;"
                    />
              <BaseInput
                  id="phoneNumber"
                  v-model="newUser.phoneNumber"
                  :error="errors && !newUser.phoneNumber"
                  :required="true"
                  :placeholder="$t('PhoneNum')"
                  style="margin-top: 5px;"
              />
                <BaseInput
                    id="phoneNumber"
                    v-model="newUser.personalCode"
                    :error="errors && !newUser.personalCode"
                    :required="true"
                    :placeholder="$t('PersonalCode')"
                    style="margin-top: 5px;"
                />


              <v-row class="register-row">
                <button
                  @click="registerUser"
                  name="register-button"
                  class="register-button content-semibold"
                >
                {{ $t("Signup") }}
                </button>
            </v-row>
            <v-row v-if="responseMessage" class="email-info-row">
              <p>{{ responseMessage }}</p>
            </v-row>
          </div>
<!--          <div-->
<!--              style="margin: 16px 32px 0px 32px"-->
<!--              v-bind:class="{-->
<!--          'text-success': formMessage.color === 'green',-->
<!--          'text-error': formMessage.color === 'red',-->
<!--        }"-->
<!--          >-->
<!--            {{ formMessage.text }}-->
<!--          </div>-->
        </v-col>
        <div class="footer-row">
          <a href="https://www.runproperty.com/">
            <img
                src="../assets/images/rpFullLogo.svg"
                alt=""
            />
          </a>
          <p style="opacity: 0.6; font-size: 12px">
            ver. {{ formatVersion(buildVersion) }}
          </p>
        </div>
      </v-col>
      <v-col
         class="image-col"
      >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BaseInput from "@/components/common/BaseInput";
import moment from "moment";
import adminApi from "@/http/admin";
import LanguageDropDown from "@/components/common/LanguageDropdown";
import router from "@/router";

export default {
  name: "SelfRegister",
  components: {
    BaseInput,
    LanguageDropDown,
  },
  data() {
    return {
      showErrors: false,
      errors: null,
      newUser: {
        firstName: "",
        lastName: "",
        personalCode: "",
        phoneNumber: "",
        email: "",
      },
      responseMessage: null,
      validate: [
        "firstName",
        "lastName",
        "personalCode",
        "email",
        "phoneNumber",
      ],
    };
  },
  computed: {
    ...mapGetters(["buildVersion"])
  },
  methods: {
    async registerUser() {
      if (!this.isFormValid()) {
        this.showErrors = true;
        return;
      }
      const newUser = {
        firstName: this.newUser.firstName,
        lastName: this.newUser.lastName,
        personalCode: this.newUser.personalCode,
        phoneNumber: this.newUser.phoneNumber,
        email: this.newUser.email,
      };
      await adminApi.createSelfUser(newUser).then(res => {
            console.log(res);
            if(res.status === 0){
                this.toastError("User with this email already exists");
            } else {
              this.responseMessage = "Confirmation link has been sent to Your email address.";
              setTimeout(() => {
                this.responseMessage = null;
                router.push({
                  name: "Login",
                  params: {
                    email: newUser.email
                  },
                });
              }, 10000);

            }
          }
      ).catch(err => {
        console.log(err);
      });


    },
    formatVersion(version) {
      const newDate = new Date(version);
      return moment(newDate).format("DDMM-HHmm");
    },
    isFormValid() {
      this.errors = this.getEmptyErrors();

      return this.validate
          .map((field) => {
            return this.isFieldValid(field);
          })
          .every((item) => item === true);
    },
    isFieldValid(fieldName) {
      switch (fieldName) {
        case "groups":
          if (this.newUser[fieldName].length < 1) {
            this.errors[fieldName] = "FieldRequired";
          }
          break;
        default:
          if (!this.newUser[fieldName]) {
            this.errors[fieldName] = "FieldRequired";
            return false;
          }
      }
      return true;
    },
    getEmptyErrors() {
      return {
        firstName: "",
        lastName: "",
        personalCode: "",
        email: "",
        phoneNumber: "",
      };
    },
  }
};
</script>

<style lang="scss" scoped>
.main-row {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #b0d9ff
}

.language-row {
  position: absolute;
  left: 56px;
  top: 36px;
  /*display: flex;
  flex-direction: row;
  width: 50%;
  margin-bottom: 6vh;*/
}

.register-col {
  display: flex;
  flex-direction: column;
  max-width: 48%;
  justify-content: center;
  padding: 24px 36px;
  align-items: center;
}
.register-row {
  margin-top: 16px !important;
}
.password-row {
  margin-top: 8px !important;
}
.form-col {
  padding: 0 12px 12px 12px;
  background-color: white;
  border-radius: 12px;
  width: 24vw;
  min-width: 300px;
  max-width: 544px;
  //min-height: 460px;
  max-height: 640px;
  margin-top: 10%;
  margin-bottom: 16%;
}
.header-row {
  width: 54%;
  min-width: 200px;
  margin: 0 auto;
}
.register-decoration-row {
  background-color: #4C8DD9;
  height: 20px;
  border-radius: 0 0 12px 12px;
}

.title-row {
  margin-top: clamp(20px, 14vh, 20px);
  margin-bottom: 1vh;
}

.register-title {
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  font-family: "Geomanist", sans-serif !important;
}

.email-row {
  padding-top: 0 !important;
  margin-top: 0 !important;
}

.register-button {
  text-transform: none;
  width: 100%;
  padding: 12px 16px;
  background-color: black !important;
  color: white !important;
  border-radius: 12px;
  text-align: center !important;
}
.logo-wrapper {
  cursor: pointer;
}
.logo {
  max-width: 128px;
  margin-top: 64px;
}
.footer-row {
  position: absolute;
  left: 56px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  width: 40%;
  justify-content: space-between;
  align-items: center;
}


.image-col {
  background-color: #f4f5f7;
  height: 100vh;
  background-image: url("../assets/images/login-general.png");
  background-position: center;
  background-size: cover;
  justify-content: center;
  align-items: flex-end;
  display: flex;
}
.company-logo {
  position: absolute;
  top: 100px;
  max-height: 94px;
}
.email-info-row{
  display: flex;
  padding: 16px;
  max-height: fit-content;
  background-color: #0AAF60;
  p{
    color: white;
  }
}

@media screen and (max-width: 900px) {
  .main-row {
    height: 110vh;
    max-width: 900px;
  }
  .form-col {
    max-height: 540px;
  }
  .footer-row {
    display: none;
  }
  .image-col {
    display: none;
  }
}
.title-row {
  padding-left: 0;
}
</style>