var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex",
      "background-color": "#b0d9ff"
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.registerUser.apply(null, arguments);
      }
    }
  }, [_c('v-row', {
    staticClass: "main-row"
  }, [_c('v-col', {
    staticClass: "register-col"
  }, [_c('div', {
    staticClass: "language-row"
  }, [_c('LanguageDropDown')], 1), _c('v-col', {
    staticClass: "form-col"
  }, [_c('div', {
    staticClass: "header-row"
  }, [_c('div', {
    staticClass: "register-decoration-row"
  }), _c('v-row', {
    staticClass: "title-row"
  }, [_c('h1', {
    staticClass: "register-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("Signup")) + " ")])]), _c('BaseInput', {
    attrs: {
      "id": "firstName",
      "error": _vm.errors && !_vm.newUser.firstName,
      "required": true,
      "placeholder": _vm.$t('FirstName')
    },
    model: {
      value: _vm.newUser.firstName,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "firstName", $$v);
      },
      expression: "newUser.firstName"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      "id": "lastName",
      "error": _vm.errors && !_vm.newUser.lastName,
      "required": true,
      "placeholder": _vm.$t('LastName')
    },
    model: {
      value: _vm.newUser.lastName,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "lastName", $$v);
      },
      expression: "newUser.lastName"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      "id": "email",
      "error": _vm.errors && !_vm.newUser.email,
      "required": true,
      "placeholder": _vm.$t('UserEmail')
    },
    model: {
      value: _vm.newUser.email,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "email", $$v);
      },
      expression: "newUser.email"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      "id": "phoneNumber",
      "error": _vm.errors && !_vm.newUser.phoneNumber,
      "required": true,
      "placeholder": _vm.$t('PhoneNum')
    },
    model: {
      value: _vm.newUser.phoneNumber,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "phoneNumber", $$v);
      },
      expression: "newUser.phoneNumber"
    }
  }), _c('BaseInput', {
    staticStyle: {
      "margin-top": "5px"
    },
    attrs: {
      "id": "phoneNumber",
      "error": _vm.errors && !_vm.newUser.personalCode,
      "required": true,
      "placeholder": _vm.$t('PersonalCode')
    },
    model: {
      value: _vm.newUser.personalCode,
      callback: function ($$v) {
        _vm.$set(_vm.newUser, "personalCode", $$v);
      },
      expression: "newUser.personalCode"
    }
  }), _c('v-row', {
    staticClass: "register-row"
  }, [_c('button', {
    staticClass: "register-button content-semibold",
    attrs: {
      "name": "register-button"
    },
    on: {
      "click": _vm.registerUser
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("Signup")) + " ")])]), _vm.responseMessage ? _c('v-row', {
    staticClass: "email-info-row"
  }, [_c('p', [_vm._v(_vm._s(_vm.responseMessage))])]) : _vm._e()], 1)]), _c('div', {
    staticClass: "footer-row"
  }, [_c('a', {
    attrs: {
      "href": "https://www.runproperty.com/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../assets/images/rpFullLogo.svg"),
      "alt": ""
    }
  })]), _c('p', {
    staticStyle: {
      "opacity": "0.6",
      "font-size": "12px"
    }
  }, [_vm._v(" ver. " + _vm._s(_vm.formatVersion(_vm.buildVersion)) + " ")])])], 1), _c('v-col', {
    staticClass: "image-col"
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }